/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Avatar,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuList,
  Modal,
  Divider,
} from '@mui/material';
import {
  KeyboardArrowDown,
  Person4,
  PowerSettingsNew,
  CancelPresentation,
  Menu as Menuicon,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

import styles from 'styles/common/layout.module.scss';
import { loginModalStyles } from 'styles/mui/common/modal-styles';
import logoImg from 'assets/logo.png';
import logoBlack from 'assets/Logo-Black.png';
import {
  navAuthLinksData,
  navAuthLoginSignupLinksData,
  navPagesCommonData,
  navPrivateLinksData,
} from 'utilities/data';
import useAuth from 'customHooks/useAuth';

import {
  navbarMenuStyles,
  navbarProfileMenuStyles,
} from 'styles/mui/common/layoutStyles';
import { navMenuPositionProps } from 'utilities/helperProps';

import Login from 'containers/pages/auth/login';
import Notifications from 'containers/common/components/Notifications';

function Navbar() {
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(null);
  const { isAuthenticated, userMembership, userProfile } = useAuth();
  const { handleLogout } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevState => !prevState);
  }, [isMenuOpen]);

  const toggleLoginModalOpen = useCallback(() => {
    setLoginModalOpen(state => !state);
  }, [loginModalOpen]);

  // const isAuthPages = useMemo(() => pathname?.includes('/auth'), [pathname]);
  const menuCondition = useMemo(() => {
    if (isMobile) {
      return isMenuOpen ? 'd-flex flex-column' : 'd-none';
    }

    return 'd-flex';
  }, [isMenuOpen, isMobile]);

  const handleClick = event => {
    setIsProfileMenuOpen(event.currentTarget);
  };
  const handleClose = () => {
    setIsProfileMenuOpen(null);
  };

  return (
    <Box sx={{ background: '#191919' }} position="sticky" top={0} zIndex={10}>
      <Container
        fixed
        maxWidth="xxl"
        className="py-2 d-flex align-items-center justify-content-between gap-4 position-relative"
      >
        <Box className={styles.logoContainer}>
          <Link to="/">
            <img src={logoImg} alt="Logo" />
          </Link>
        </Box>

        <IconButton className="d-flex d-md-none" onClick={toggleMenu}>
          <Menuicon className="text-white" />
        </IconButton>

        <Box
          className={`${menuCondition} align-items-center gap-3`}
          sx={navbarMenuStyles(isMenuOpen)}
        >
          {navPagesCommonData.map((page, index) => (
            <Link
              key={index}
              to={page.path}
              className="resetLink"
              onClick={isMobile ? toggleMenu : undefined}
            >
              <Typography variant="body1" color="white" fontWeight={400}>
                {page.title}
              </Typography>
            </Link>
          ))}
          {isAuthenticated &&
            navPrivateLinksData.map((link, index) => (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            ))}

          {!isAuthenticated &&
            navAuthLinksData.map((link, index) => (link.isLogin ? (
              <Typography
                key={index}
                onClick={toggleLoginModalOpen}
                variant="body1"
                color="white"
                className="pointer"
                fontWeight={400}
              >
                {link.title}
              </Typography>
            ) : (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            )))}

          {!isMobile && !isAuthenticated && (
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                height: '25px',
                bgcolor: '#ffffff',
                border: '1px solid #ffffff',
              }}
              flexItem
            />
          )}

          {!isAuthenticated &&
            navAuthLoginSignupLinksData.map((link, index) => (link.isLogin ? (
              <Typography
                key={index}
                onClick={toggleLoginModalOpen}
                variant="body1"
                color="white"
                className="pointer"
                fontWeight={400}
              >
                {link.title}
              </Typography>
            ) : (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            )))}
          {isAuthenticated && (
            <Box className="d-flex align-items-center gap-2">
              <Notifications />
              <Avatar
                alt={userProfile?.first_name}
                src={userProfile?.image}
                sx={{ width: 58, height: 58 }}
              />
              <Box className="d-flex gap-1 pointer" onClick={handleClick}>
                <Box>
                  <Typography
                    color="white"
                    variant="body2"
                    fontSize="16px"
                    fontWeight={500}
                  >
                    {userProfile?.first_name} {userProfile?.last_name}
                  </Typography>
                  <Typography color="white" variant="body2">
                    {userMembership?.membership_name} Member
                  </Typography>
                </Box>
                <KeyboardArrowDown sx={{ color: 'white' }} />
              </Box>
            </Box>
          )}
        </Box>
        <Menu
          key={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          anchorEl={isProfileMenuOpen}
          onClose={handleClose}
          {...navMenuPositionProps}
          sx={navbarProfileMenuStyles}
        >
          <MenuList sx={{ outline: 'none', border: 'none' }} disablePadding>
            <MenuItem
              dense
              onClick={() => {
                if (isMobile) toggleMenu();
                navigate('profile');
                handleClose();
              }}
            >
              <ListItemIcon>
                <Person4 fontSize="small" />
              </ListItemIcon>
              <ListItemText fontSize="12px">My Profile</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                handleLogout();

                if (isMobile) toggleMenu();
              }}
            >
              <ListItemIcon>
                <PowerSettingsNew fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
        <Modal open={loginModalOpen} onClose={toggleLoginModalOpen}>
          <Box sx={loginModalStyles}>
            <CancelPresentation className="pointer" onClick={toggleLoginModalOpen} />
            <Box className="d-flex justify-content-center align-items-center">
              <img src={logoBlack} alt="Logo" width="50%" />
            </Box>
            <Login onClose={toggleLoginModalOpen} />
          </Box>
        </Modal>
      </Container>
    </Box>
  );
}

export default Navbar;
