import { publicAPI } from '.';

export const accountVerificationApi = publicAPI.injectEndpoints({
  endpoints: build => ({
    activateAcccount: build.mutation({
      query: token => ({
        url: `/booking/activate-account/${token}`,
        method: 'POST',
      }),
    }),
    resendActivateAcccount: build.mutation({
      query: email => ({
        url: '/booking/resend-activation/',
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const { useActivateAcccountMutation, useResendActivateAcccountMutation } =
  accountVerificationApi;
