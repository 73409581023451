// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kalnia:wght@100..700&family=Poppins:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #fffffd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

p {
  margin-bottom: 0 !important;
}

.pointer {
  cursor: pointer;
}

.required::after {
  content: "*";
  color: red;
}

.resetLink {
  text-decoration: none;
  color: unset;
}

.swiper-button-prev,
.swiper-button-next {
  color: #d4b35b;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;AAFF;;AAKA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,kCAAA;AAFF;;AAKA;EACE,2BAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAME;EACE,YAAA;EACA,UAAA;AAHJ;;AAOA;EACE,qBAAA;EACA,YAAA;AAJF;;AAOA;;EAEE,cAAA;AAJF","sourcesContent":["// ----------------------------------------------------------------------\n// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Kalnia:wght@100..700&family=Poppins:wght@300;400;500;600;700;800&display=swap');\n\nbody {\n  background: #fffffd;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Poppins', sans-serif;\n}\n\np {\n  margin-bottom: 0 !important;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.required {\n  &::after {\n    content: '*';\n    color: red;\n  }\n}\n\n.resetLink {\n  text-decoration: none;\n  color: unset;\n}\n\n.swiper-button-prev,\n.swiper-button-next {\n  color: #d4b35b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
