import { SOCKET_URL } from 'utilities/constants';

const token = localStorage.getItem('token');

// Constants
export const ALL_ROOM_SOCKET_URL = `${SOCKET_URL}/chat/update/?token=${token}`;

export const webSocketUrl = roomId => `${SOCKET_URL}/dashboard/chat/?token=${token}&room_id=${roomId}`;
export const getUserOnlineURL = `${SOCKET_URL}/online/status/?token=${token}`;

export const getNotificationUrl = () => `${SOCKET_URL}/notification/detail/?token=${token}`;
