// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_logoContainer__Yv52B img {
  width: 230px;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .layout_logoContainer__Yv52B img {
    width: 250px;
  }
}

.layout_mainContainer__G42Gv {
  padding: 0;
}

.layout_whatsappIconBox__xVDYE {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  z-index: 99999;
}
.layout_whatsappIconBox__xVDYE img {
  width: 60px;
  filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5)) saturate(0);
}

.layout_footerContainer__CAPet {
  background-color: #191919;
  color: white;
  padding: 50px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/layout.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,eAAA;AAAJ;AAEI;EAJF;IAKI,YAAA;EACJ;AACF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AAAF;AAEE;EACE,WAAA;EACA,+DAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;AADF","sourcesContent":[".logoContainer {\n  & img {\n    width: 230px;\n    max-width: 100%;\n\n    @media screen and (max-width: 768px) {\n      width: 250px;\n    }\n  }\n}\n\n.mainContainer {\n  padding: 0;\n}\n\n.whatsappIconBox {\n  position: fixed;\n  right: 10px;\n  bottom: 10px;\n  cursor: pointer;\n  z-index: 99999;\n\n  & img {\n    width: 60px;\n    filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5)) saturate(0);\n  }\n}\n\n.footerContainer {\n  background-color: #191919;\n  color: white;\n  padding: 50px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `layout_logoContainer__Yv52B`,
	"mainContainer": `layout_mainContainer__G42Gv`,
	"whatsappIconBox": `layout_whatsappIconBox__xVDYE`,
	"footerContainer": `layout_footerContainer__CAPet`
};
export default ___CSS_LOADER_EXPORT___;
