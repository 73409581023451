const { publicAPI } = require('.');

export const authApi = publicAPI.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      query: body => ({
        url: '/auth/login/',
        method: 'POST',
        body,
      }),
    }),

    forgotPassword: build.mutation({
      query: body => ({
        url: '/booking/forgot-password/',
        method: 'POST',
        body,
      }),
    }),

    signup: build.mutation({
      query: body => ({
        url: '/create/booking-user/',
        method: 'POST',
        body,
      }),
    }),

    resetPassword: build.mutation({
      query: body => ({
        url: '/booking/reset-password/',
        method: 'POST',
        body,
      }),
    }),

    verifyToken: build.mutation({
      query: () => {
        const token = localStorage.getItem('token');
        return {
          url: '/api/token/verify/',
          method: 'POST',
          body: { token },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useSignupMutation,
  useResetPasswordMutation,
  useVerifyTokenMutation,
} = authApi;
