import { privateAPI } from '.';

export const notificationsApi = privateAPI.injectEndpoints({
  endpoints: build => ({
    getNotification: build.query({
      query: () => '/notifications/list/',
    }),

    readNotification: build.mutation({
      query: body => ({
        url: `/notifications/update/${body?.id}/`,
        method: 'PATCH',
        body: { is_read: body.isRead, is_delete: false },
      }),
    }),

    readAllNotifications: build.mutation({
      query: () => ({
        url: '/notifications/read-all/',
        method: 'POST',
      }),
    }),

  }),
});

export const { useGetNotificationQuery, useLazyGetNotificationQuery, useReadNotificationMutation, useReadAllNotificationsMutation } =
  notificationsApi;
