// LIVE DOMAIN
// export const API_URL = 'https://sublime.beyonderissolutions.com/api';

// STAGING DOMAIN
export const API_URL = 'https://sublime-staging.beyonderissolutions.com/api';
export const SOCKET_URL = 'wss://sublime-staging.beyonderissolutions.com/api';

// LOCAL DOMAIN
// export const API_URL = 'http://192.168.100.76:8000/api';

export const test = '';
