import React, { useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import useAuth from 'customHooks/useAuth';

function NavColumn({ label, links }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleNavigate = useCallback(path => {
    navigate(path);
  }, []);

  return (
    <Box>
      <Typography variant="body1" fontSize={18} fontWeight={600} color="primary">
        {label}
      </Typography>

      <Stack spacing={1.5} mt={2}>
        {links
          .filter(link => !link.isAuth || (link.isAuth && !isAuthenticated))
          .map(link => (
            <Box className="d-flex gap-2">
              {link?.icon && link.icon}
              <Typography
                key={link.label}
                className={link?.path ? 'pointer' : ''}
                onClick={link.path ? () => handleNavigate(link.path) : undefined}
              >
                {link.label}
              </Typography>
            </Box>
          ))}
      </Stack>
    </Box>
  );
}

NavColumn.propTypes = {
  label: propTypes.string.isRequired,
  links: propTypes.arrayOf(
    propTypes.shape({
      path: propTypes.string,
      label: propTypes.string,
    })
  ),
};

NavColumn.defaultProps = {
  links: [],
};

export default NavColumn;
