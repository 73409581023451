import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import styles from 'styles/common/layout.module.scss';
import logoImg from 'assets/logo.png';
import { footerContactLinksData, footerLinksData } from 'utilities/data';
import NavColumn from './NavColumn';

function Footer() {
  return (
    <Box className={styles.footerContainer}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="start" rowGap={4}>
          <Grid item xs={6} sm={6} md={2} order={{ xs: 2, sm: 2, md: 1 }} pt={{ md: 3 }}>
            <NavColumn label="Links" links={footerLinksData} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className="d-flex align-items-center flex-column flex-grow-1"
            order={{ xs: 1, sm: 1, md: 2 }}
          >
            <img className="mb-3" src={logoImg} alt="Logo" />

            <Typography variant="body1" className="text-center">
              Experience Luxury Living, Where Sophistication Meets Serenity!
            </Typography>
            <Typography variant="body2" className="text-center mt-3">
              Copyright &#169; 2024 Sublime. All Rights Reserved.
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            className="text-start"
            order={{ xs: 3, sm: 3, md: 3 }}
            pt={{ md: 3 }}
          >
            <NavColumn label="Contact" links={footerContactLinksData} isRTL />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
