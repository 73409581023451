import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const useHandleApiResponse = (
  error,
  isSuccess,
  message = 'Operation Successfull!',
  successLink = false
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess && message) {
      enqueueSnackbar(message, { variant: 'success' });

      if (successLink) {
        navigate(successLink);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error && 'data' in error) {
      const {
        error: respError,
        message: respMessage,
        detail: detailMessage,
      } = error.data;
      const nonFieldErrors = error.data?.non_field_errors;

      if (nonFieldErrors?.length > 0) {
        nonFieldErrors.forEach(err => {
          enqueueSnackbar(err, { variant: 'error' });
        });
      }
      if (respMessage?.length > 0 && typeof respMessage === 'object') {
        respMessage?.map(err => enqueueSnackbar(err, { variant: 'error' }));
      } else {
        enqueueSnackbar(
          respError || respMessage || detailMessage || 'Something went wrong!',
          {
            variant: 'error',
          }
        );
      }
    }
  }, [error]);

  return null;
};

export default useHandleApiResponse;
