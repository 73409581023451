import { privateAPI } from '.';

export const userAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getUserDetail: build.query({
      query: () => '/auth/me/',
      providesTags: ['GetUserDetails'],
    }),
  }),
});

export const { useGetUserDetailQuery, useLazyGetUserDetailQuery } = userAPI;
