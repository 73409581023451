import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

// COMPONENTS & CUSTOM HOOKS & UTILITIES
import useScrollToTop from 'customHooks/useScrollToTop';
// import styles from 'styles/common/layout.module.scss';
// import whatsappIcon from 'assets/whatsapp-icon.webp';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function LayoutWrapper() {
  useScrollToTop();

  return (
    <Box>
      <Navbar />

      <Box>
        <Outlet />
      </Box>

      <Footer />

      {/* <Box className={styles.whatsappIconBox}>
        <img src={whatsappIcon} alt="Whatsapp" />
      </Box> */}
    </Box>
  );
}

export default LayoutWrapper;
