import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onLoggedOut } from 'store/slices/authSlice';

const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(state => state.auth);

  const userMembership = useMemo(() => user?.profile?.membership, [user]);
  const userProfile = useMemo(() => user?.profile, [user]);

  const handleLogout = useCallback(() => {
    dispatch(onLoggedOut());
  }, [dispatch]);

  return { handleLogout, isAuthenticated, user, userMembership, userProfile };
};

export default useAuth;
