const navbar = '120px';

export const navbarMenuStyles = isMenuOpen => ({
  ...(isMenuOpen && {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '350px',
    background: '#191919',
    justifyContent: 'center',
    overFlow: 'auto'
  }),
});

export const navbarProfileMenuStyles = {
  '& .MuiList-root': {
    paddingTop: 0,
  },
  '& .MuiMenuItem-root': {
    backgroundColor: '#fff',
  },
  '& .MuiMenuItem-root:focus': {
    backgroundColor: '#fff',
  },
  '& .MuiListItemIcon-root': {
    color: 'primary.main',
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: 'primary.main',
    color: 'white',
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
  },
};

export const authContainerStyles = {
  minHeight: '500px',
  height: `calc(80vh - ${navbar})`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& form': {
    width: '100%',
  },
};

export const notificationMenuStyles = {
  '& .MuiPaper-root': {
    minWidth: '400px',
    marginTop: '10px',
    borderRadius: '10px',
  },
};
