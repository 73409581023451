import { styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack';

const SuccessSnackbar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    background: 'white',
    fontSize: '18px',
    color: '#D4B35B',

    '& svg': {
      width: '24px !important',
      height: '24px !important',
    },
  },
}));

export default SuccessSnackbar;
