import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  allowed_modules: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoggedIn: (state, action) => {
      const userDetails = { ...action.payload };
      delete userDetails.access;

      localStorage.setItem('token', action.payload.access);
      localStorage.setItem('refreshToken', action.payload.refresh);

      return {
        ...state,
        isAuthenticated: true,
        ...userDetails,
      };
    },
    getUserDetail: (state, action) => {
      const userDetails = { ...action.payload };
      delete userDetails.allowed_modules;

      return {
        ...state,
        isAuthenticated: true,
        user: userDetails,
        allowed_modules: action.payload.allowed_modules,
      };
    },
    onLoggedOut: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      return { ...initialState };
    },
  },
});

export const { onLoggedIn, getUserDetail, onLoggedOut } = authSlice.actions;
export default authSlice.reducer;
