import React, { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import LayoutWrapper from 'containers/common/layout';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

// AUTH ROUTES
const Login = lazy(() => import('containers/pages/auth/login'));
const MembershipPlans = lazy(() => import('containers/pages/auth/membershipPlans'));
const Signup = lazy(() => import('containers/pages/auth/signup'));
const SignupConfirmation = lazy(() => import('containers/pages/auth/signupConfirmation'));
const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
const ResetPassword = lazy(() => import('containers/pages/auth/resetPassword'));
const ResetPasswordConfirmation = lazy(
  () => import('containers/pages/auth/resetPasswordConfirmation')
);
const ForgotPasswordConfirmation = lazy(
  () => import('containers/pages/auth/forgotPasswordConfirmation')
);
const AccountVerified = lazy(() => import('containers/pages/auth/accountVerified'));

// PAYMENT ROUTES
const PaymentSuccess = lazy(
  () => import('containers/pages/auth/membershipPlans/SuccessPaymentScreen')
);
const PaymentDecline = lazy(
  () => import('containers/pages/auth/membershipPlans/DeclinePaymentScreen')
);
const PaymentCancel = lazy(
  () => import('containers/pages/auth/membershipPlans/CancelPaymentScreen')
);

// MY BOOKINGS ROUTES
const MyBookings = lazy(() => import('containers/pages/myBookings'));
const BookingDetails = lazy(() => import('containers/pages/myBookings/bookingDetails'));

const About = lazy(() => import('containers/pages/about'));
const Contact = lazy(() => import('containers/pages/contact'));
const SplashScreen = lazy(() => import('containers/pages/splashScreen'));
const Home = lazy(() => import('containers/pages/home'));
const Hotels = lazy(() => import('containers/pages/hotels'));
const HotelDetails = lazy(() => import('containers/pages/hotelDetails'));
const BookingSummary = lazy(() => import('containers/pages/bookingSummary'));
const BookingConfirmation = lazy(() => import('containers/pages/bookingConfirmation'));

// USER PROFILE ROUTES
const UserProfile = lazy(() => import('containers/pages/profile'));

// CUSTOMER SERVICE
const CustomerService = lazy(() => import('containers/pages/customerServices'));
const Chat = lazy(() => import('containers/pages/chat'));

function AppRoutes() {
  return (
    <Suspense fallback={<GlobalLoader />}>
      <Routes>
        <Route path="/">
          <Route element={<PublicRoutes />}>
            <Route index element={<SplashScreen />} />
            <Route path="contact" element={<Contact />} />
            <Route path="auth" element={<Outlet />}>
              <Route path="login" element={<Login />} />
              <Route path="account-verified/:token" element={<AccountVerified />} />
              <Route path="signup" element={<Outlet />}>
                <Route index element={<Signup />} />
                <Route path="confirmation" element={<SignupConfirmation />} />
                <Route path="payment-success" element={<PaymentSuccess />} />
                <Route path="payment-decline" element={<PaymentDecline />} />
                <Route path="payment-cancel" element={<PaymentCancel />} />
              </Route>
              <Route path="membership-plans" element={<MembershipPlans />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="forgot-password-confirmation"
                element={<ForgotPasswordConfirmation />}
              />
              <Route path="reset-password/:uid/:token" element={<ResetPassword />} />
              <Route
                path="reset-password-confirmation"
                element={<ResetPasswordConfirmation />}
              />
            </Route>
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="home" element={<Home />} />
            {/* User Profile Routes */}
            <Route path="profile" element={<UserProfile />} />
            {/* Hotel Bookings Routes */}
            <Route path="hotels" element={<Outlet />}>
              <Route index element={<Hotels />} />
              <Route path="details" element={<Outlet />}>
                <Route index element={<HotelDetails />} />
                <Route path="booking-summary/:roomId" element={<BookingSummary />} />
              </Route>
              <Route path="booking-confirmation" element={<BookingConfirmation />} />
            </Route>
            {/* My Bookings Routes */}
            <Route path="my-bookings" element={<Outlet />}>
              <Route index element={<MyBookings />} />
              <Route path=":id" element={<BookingDetails />} />
            </Route>
            {/* Customer Services */}
            <Route path="customer-service" element={<Outlet />}>
              <Route index element={<CustomerService />} />
              <Route path="chat" element={<Chat />} />
            </Route>
          </Route>

          <Route element={<LayoutWrapper />}>
            <Route path="about" element={<About />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
