import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { useLoginMutation } from 'services/public/auth';
import { useResendActivateAcccountMutation } from 'services/public/accountVerification';
import { onLoggedIn } from 'store/slices/authSlice';
import FormikField from 'shared/FormikField';
import SubmitBtn from 'shared/SubmitBtn';
import { loginInitValues, loginValidationSchema } from './utilities/formUtils';

function Login({ onClose }) {
  const [isInActiveUser, setIsInActiveUser] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { error, isSuccess }] = useLoginMutation();
  const [
    resendActivateAcccount,
    { error: reError, isSuccess: reIsSuccess, reIsLoading },
  ] = useResendActivateAcccountMutation();
  useHandleApiResponse(error, isSuccess, 'Login Successfully');
  useHandleApiResponse(reError, reIsSuccess, 'Check your email for verification link');

  return (
    <Container fixed maxWidth="md">
      <Formik
        initialValues={loginInitValues}
        validationSchema={loginValidationSchema}
        onSubmit={async values => {
          const resp = await login(values);
          if (resp?.error?.data?.user_active?.[0] === 'false') {
            setIsInActiveUser(true);
            setEmail(values?.email);
            return;
          }
          if (resp.data.user.profile.expire_membership) {
            onClose();
            navigate('/auth/membership-plans', {
              replace: true,
              state: {
                token: resp.data.access,
              },
            });
            return;
          }
          if (resp?.data) {
            onClose();
            dispatch(onLoggedIn(resp.data));
            navigate('/home', { replace: true });
          }
        }}
      >
        {() => (
          <Form>
            <Grid container columnSpacing={2} rowGap={2} justifyContent="center">
              <Grid item xs={12} className="d-flex flex-column align-items-start gap-4">
                <Typography fontSize="24px" fontWeight={500}>
                  Sign In
                </Typography>
                <FormikField
                  label="Email:"
                  placeholder="email@.com"
                  name="email"
                  variant="outlined"
                />

                <FormikField
                  label="Password:"
                  placeholder="Password"
                  name="password"
                  variant="outlined"
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="d-flex justify-content-end mb-2" onClick={onClose}>
                  <Link to="/auth/forgot-password">
                    <Button color="grayColor">Forgot password?</Button>
                  </Link>
                </Box>
                <Box my={2}>
                  <SubmitBtn
                    align="center"
                    label="Sign in"
                    size="large"
                    sx={{ width: '50%', borderRadius: '8px' }}
                  />
                </Box>

                <Stack gap={2} alignItems="center">
                  {isInActiveUser && (
                    <>
                      <Typography variant="body2" color="error">
                        Inactive account. Please verify your email.
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={
                          reIsLoading ? <CircularProgress size={20} /> : undefined
                        }
                        disabled={reIsLoading}
                        onClick={() => resendActivateAcccount(email)}
                      >
                        Resend Link
                      </Button>
                    </>
                  )}
                  <Box onClick={onClose}>
                    Click <Link to="/auth/signup">Here</Link> to Signup
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

Login.propTypes = {
  onClose: propTypes.func.isRequired,
};

Login.defadefaultPropsult = {
  onClose: () => {},
};

export default Login;
