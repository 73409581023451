import { createSlice } from '@reduxjs/toolkit';

const initialState = JSON.parse(sessionStorage.getItem('search'));

const searchSlice = createSlice({
  name: 'searchDetails',
  initialState,
  reducers: {
    updateSearch: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateSearch } = searchSlice.actions;
export default searchSlice.reducer;
