export const navMenuPositionProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  MenuListProps: {
    'aria-labelledby': 'basic-button',
  }
};

export const test = {};
