export const modalCommonStyles = {
  maxWidth: '100%',
  width: '500px',
  background: 'white !important',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
  border: 'none',
  maxHeight: '95vh',
  overflowY: 'auto',
  borderRadius: '5px',
  padding: '15px 20px',
};

export const roomInfoModalStyles = {
  ...modalCommonStyles,
  borderRadius: '20px',
  width: '900px',
};

export const loginModalStyles = {
  ...modalCommonStyles,
  borderRadius: '20px',
};
