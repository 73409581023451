import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import propTypes from 'prop-types';

function SubmitBtn({ label, variant, color, align, fullWidth, size, sx }) {
  const { isSubmitting } = useFormikContext();

  return (
    <Box textAlign={align} width={fullWidth ? 1 : undefined}>
      <Button
        variant={variant}
        color={color}
        type="submit"
        startIcon={isSubmitting ? <CircularProgress size={20} /> : undefined}
        disabled={isSubmitting}
        className="text-capitalize"
        fullWidth={fullWidth}
        size={size}
        sx={sx}
      >
        {isSubmitting ? 'Loading...' : label}
      </Button>
    </Box>
  );
}

SubmitBtn.propTypes = {
  label: propTypes.string,
  variant: propTypes.string,
  color: propTypes.string,
  align: propTypes.oneOf(['left', 'center', 'right']),
  fullWidth: propTypes.bool,
  size: propTypes.string,
  sx: propTypes.object
};

SubmitBtn.defaultProps = {
  label: 'Submit',
  variant: 'contained',
  color: 'primary',
  align: 'left',
  fullWidth: false,
  size: 'medium',
  sx: {}
};

export default SubmitBtn;
