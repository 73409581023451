import React, { memo } from 'react';

import LayoutWrapper from 'containers/common/layout';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicRoutes() {
  const { state } = useLocation();
  const { isAuthenticated } = useSelector(reduxState => reduxState.auth);

  return isAuthenticated ? <Navigate to={state?.from || '/home'} /> : <LayoutWrapper />;
}

export default memo(PublicRoutes);
